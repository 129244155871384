<template>
  <div class="popup popup02">
    <v-card tile>
      <v-card-title class="popup-title">입금내용확인</v-card-title>
      <fieldset class="pp">
        <h3 class="mb-2">입금 내역 [연구비관리시스템]
          <select v-model="projectCode" class="ml-6" style="width:200px;border: 1px solid #aaaaaa;font-weight: normal;">
            <option
              v-for="project in cbnuProjects"
              :key="project.projectCode"
              :value="project.projectCode"
            >{{ project.projectName }}
            </option>
          </select>
        </h3>
        <div style="border-top: 2px solid #2c3976;">
          <v-simple-table
            fixed-header
            height="500px"
            class="ma-0"
            style="border-top: none"
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="td_dark_blue">NO</th>
                <th class="td_dark_blue">입금일</th>
                <th class="td_dark_blue">업체명</th>
                <th class="td_dark_blue">계산서 발행일</th>
                <th class="td_dark_blue">공급가액(원)</th>
                <th class="td_dark_blue">입금금액(원)</th>
              </tr>
              </thead>
              <thead v-if="loading">
              <tr>
                <th colspan="6" class="td_loading">
                  <v-progress-linear
                    indeterminate
                    color="#0f2585"
                  ></v-progress-linear>
                </th>
              </tr>
              </thead>
              <tbody v-if="cbnuDepositList.length > 0">
              <tr v-for="(cbnuDeposit, index) in cbnuDepositList" :key="index" :class="{'even': index % 2 > 0}">
                <td class="td_blueA">{{ index + 1 }}</td>
                <td class="td_blueA">{{ dateFormat(cbnuDeposit.slipDate) }}</td>
                <td class="td_blueA">{{ cbnuDeposit.custName }}</td>
                <td class="td_blueA">{{ dateFormat(cbnuDeposit.billDate) }}</td>
                <td class="td_blueA">{{ moneyFormat(cbnuDeposit.supplyAmount) }}</td>
                <td class="td_blueA">{{ moneyFormat(cbnuDeposit.totalAmount) }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td class="td_blueA" colspan="6">데이터를 찾을 수 없습니다.</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="butt">
          <button id="confirm01" type="button" class="pop-btn" @click="close">확인</button>
        </div>

      </fieldset>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'MainDepositDialog',
  data() {
    return {
      loading: false,
      projectCode: null,
      cbnuProjects: [],
      cbnuDepositList: []
    }
  },
  watch: {
    projectCode(val) {
      if (val) {
        this.searchCbnuDepositList()
      }
    }
  },
  mounted() {
    this.activateMultipleDraggableDialogs()
    this.getCbnuProjects()
    .then(() => {
      this.projectCode = this.cbnuProjects[0].projectCode
      this.searchCbnuDepositList()
    })
  },
  methods: {
    getCbnuProjects() {
      return this.$http.get('/api/contract/cbnu/projects')
      .then(({ data }) => {
        this.cbnuProjects = data
      })
    },
    searchCbnuDepositList() {
      this.loading = true
      return this.$http.get(`/api/main/cbnudeposits/${this.projectCode}`)
      .then(({ data }) => {
        this.cbnuDepositList = data
        this.loading = false
      })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
