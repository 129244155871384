<template>
  <v-container fluid class="mb-10">
    <div class="autho">
      <div class="shbtn03">
        <input v-model="param.startDate" type="date" class="blue_input01" max="9999-12-31"><span style="vertical-align: super;">~</span><input v-model="param.endDate" type="date" class="blue_input01" max="9999-12-31">
        <a @click="search"></a>
<!--        <v-btn-->
<!--            dark-->
<!--            tile-->
<!--            color="#6999f1"-->
<!--            class="font-weight-bold"-->
<!--            width="100px"-->
<!--            @click="search"-->
<!--        >-->
<!--          검색-->
<!--        </v-btn>-->
      </div>
    </div>
    <!--.autho-->


    <div class="adminCont">
      <div class="adminTab">
        <ul class="clear pa-0">
          <li>
            <ul class="resgister pa-0">
              <li>
                <a @click="moveDetail('apply', 'RI')">
                  <p class="txt">신청</p>
                  <strong>{{ state.riCount }}</strong>

                  
                  <span class="iWrap"> <i class="icon"></i> </span>

                </a>
              </li>
            </ul>

          </li>
          <li>
            <ul class="approve pa-0">
              <li>
                <a @click="moveDetail('apply', 'AP')">
                  <p class="txt">승인</p>
                  <strong>{{ state.apCount }}</strong>

									
                  <span class="iWrap"> <i class="icon"></i> </span>
                </a>
              </li>
            </ul>

          </li>
          <li>
            <ul class="contrat pa-0">
              <li>
                <a @click="moveDetail('search', 'CI')">
                  <p class="txt">계약체결</p>
                  <strong>{{ state.ciCount }}</strong>

									
                  <span class="iWrap"> <i class="icon"></i> </span>
                </a>
              </li>
            </ul>

          </li>
          <li>
            <ul class="monney pa-0">
              <li>
                <a @click="moveDetail('search', 'TD')">
                  <p class="txt">기술료 입금</p>
                  <strong>{{ state.tdCount }}</strong>

									
                  <span class="iWrap"> <i class="icon"></i> </span>
                </a>
              </li>
            </ul>

          </li>
          <li>
            <ul class="end pa-0">
              <li>
                <a @click="moveDetail('search', 'ED')">
                  <p class="txt">종료</p>
                  <strong>{{ state.edCount }}</strong>

									
                  <span class="iWrap"> <i class="icon"></i> </span>
                </a>
              </li>
            </ul>

          </li>


        </ul>

      </div><!--adminTab-->

      <fieldset>
        <div class="admintable clear">
          <div class="adminTa01">
            <span class="caption1">최근 작업내역</span>
            <table  cellspacing="0" cellpadding="1px" width="100%">

              <colgroup>
                <col width="6%">
                <col width="20%">
                <col width="18%">
                <col width="16%">
                <col width="24%">
                <col width="16%">
              </colgroup>
              <thead>
              <tr>
                <th class="tit">NO</th>
                <td class="tit">신청번호</td>
                <td class="tit">업무절차</td>
                <td class="tit">책임자</td>
                <td class="tit">기술유형</td>
                <td class="tit">업체명</td>
              </tr>
              </thead>
              <tbody v-if="workList.length > 0">
              <tr v-for="(work, index) in workList" :key="index" @dblclick="openApplication(work)">
                <td class="td_blueA">{{ index + 1 }}</td>
                <td class="td_blueA">{{ work.applyNo }}</td>
                <td class="td_blueA">{{ work.workFlowName }}</td>
                <td class="td_blueA">{{ work.techTransManagerName }}</td>
                <td class="td_blueA">{{ work.techType }}</td>
                <td class="td_blueA">{{ work.companyName }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="6" class="td_blueA text-center">데이터를 찾을 수 없습니다.</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="adminTa02">
            <span class="caption1">기술료 배분 확인</span>
            <table  cellspacing="0" cellpadding="1px" width="100%">

              <colgroup>
                <col width="6%">
                <col width="20%">
                <col width="18%">
                <col width="16%">
                <col width="24%">
                <col width="16%">
              </colgroup>
              <thead>
              <tr>
                <th class="tit">NO</th>
                <td class="tit">신청번호</td>
                <td class="tit">업무절차</td>
                <td class="tit">책임자</td>
                <td class="tit">기술유형</td>
                <td class="tit">업체명</td>
              </tr>
              </thead>
              <tbody v-if="notDistributeList.length > 0">
              <tr v-for="(work, index) in notDistributeList" :key="index" @dblclick="openPaymentView(work)">
                <td class="td_blueA">{{ index + 1 }}</td>
                <td class="td_blueA">{{ work.applyNo }}</td>
                <td class="td_blueA">{{ work.workFlowName }}</td>
                <td class="td_blueA">{{ work.techTransManagerName }}</td>
                <td class="td_blueA">{{ work.techType }}</td>
                <td class="td_blueA">{{ work.companyName }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="6" class="td_blueA text-center">데이터를 찾을 수 없습니다.</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div><!--.admintable-->

        <div class="admintableB clear">
          <div class="adminTa03">
            <span class="caption1" style="display: inline-block">공지사항</span><button type="button" id="modal-open100" @click="$router.push('/notice')">+</button>
            <table  cellspacing="0" cellpadding="1px" width="100%">

              <colgroup>
                <col width="6%">
                <col width="49%">
                <col width="10%">
                <col width="10%">
                <col width="15%">
                <col width="10%">
              </colgroup>
              <thead>
              <tr>
                <th class="tit">NO</th>
                <td class="tit">제목</td>
                <td class="tit">첨부파일</td>
                <td class="tit">작성자</td>
                <td class="tit">등록일</td>
                <td class="tit">조회수</td>
              </tr>
              </thead>
              <tbody v-if="noticeList.length > 0">
              <tr
                v-for="notice in noticeList"
                :key="notice.seq"
              >
                <td class="td_blueA" @click="openNotice(notice)">{{ notice.noticeYn === 'Y' ? '공지' : notice.seq }}</td>
                <td class="td_blueA text-left px-2" @click="openNotice(notice)">{{ notice.subject }}</td>
                <td class="td_blueA" @click="openNotice(notice)"><v-icon v-if="notice.attachYn === 'Y'">mdi-paperclip</v-icon></td>
                <td class="td_blueA" @click="openNotice(notice)">{{ notice.writerName }}</td>
                <td class="td_blueA" @click="openNotice(notice)">{{ notice.createdDate }}</td>
                <td class="td_blueA" @click="openNotice(notice)">{{ notice.views }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td class="td_blueA" colspan="6">데이터를 찾을 수 없습니다.</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="adminTa04">
            <span class="caption1" style="display: inline-block">이슈로 보는 R&amp;D</span>
            <v-row>
              <v-col
                v-for="(ntis, index) in ntisList"
                :key="index"
                cols="4"
              >
                <v-card
                  outlined
                  hover
                  :href="`http://www.ntis.go.kr/issuernd/sns/${ntis.topicNo}`"
                  target="_blank"
                >
                  <v-img
                    :src="`http://www.ntis.go.kr/ppm/api/selectMainImgSrc.do?topicNo=${ntis.topicNo}&imgType=main`"
                    height="100"
                  />
                  <v-list class="pa-0 pt-2" two-line>
                    <v-list-item class="px-2 align-items-start" style="min-height: 50px;">{{ ntis.topicNm }}</v-list-item>
                  </v-list>
                  <!--                  <v-card-title class="pa-2" style="font-size: 1rem">{{ ntis.topicNm }}</v-card-title>-->
                  <v-card-subtitle class="py-1 px-2" style="height: 60px">
                    <template
                      v-for="(keyword, kIdx) in ntis.kywd"
                    >
                      <span :key="kIdx">
                        #{{ keyword }}
                      </span>
                    </template>
                  </v-card-subtitle>
                  <v-card-text class="pa-2">{{ ntis.extrDt }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>

      </fieldset>
    </div>
    <!--codeCont-->
    <!--업체정보 입력-->

    <loading-dialog :loading="loading"/>
    <v-dialog v-model="depositDialog" max-width="900px" @keydown.esc="depositDialog = false">
      <main-deposit-dialog v-if="depositDialog" @close="depositDialog = false"/>
    </v-dialog>

    <v-dialog v-model="noticeDialog" max-width="1003px" @keydown.esc="noticeDialog = false">
      <notice-view-dialog v-if="noticeDialog" :item="selectedNotice" @close="noticeDialog = false"/>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import LoadingDialog from '@/views/dialog/LoadingDialog'
import {mapGetters} from "vuex";
import MainDepositDialog from "@/views/main/DepositDialog";
import NoticeViewDialog from "@/views/notice/dialog/NoticeViewDialog.vue";

export default {
  name: 'Dashboard',
  components: {NoticeViewDialog, MainDepositDialog, LoadingDialog},
  data() {
    return {
      loading: false,
      depositDialog: false,
      param: {
        startDate: null,
        endDate: null
      },
      state: {
        riCount: 0,
        apCount: 0,
        ciCount: 0,
        tdCount: 0,
        edCount: 0,
        rcCount: 0,
        ccCount: 0,
        rbCount: 0,
        approvalWaitCount: 0,
				processCount: 0,
        notExistCount: 0,
        notPaidCount: 0,
        notDepositCount: 0
      },
      workList: [],
      managerWorkStateList: [],
      depositList: [],
      cbnuDepositList: [],
			approvalWaitList: [],
      notDistributeList: [],
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor:      ['#8250c4', '#5ecbc8', '#438fff', '#ff977e', '#eb5757', '#5b2071', '#ec5a96', '#a43876', '#f3c911', '#107c10'],
            hoverBackgroundColor: ['#8250c4', '#5ecbc8', '#438fff', '#ff977e', '#eb5757', '#5b2071', '#ec5a96', '#a43876', '#f3c911', '#107c10'],
            hoverBorderColor:     ['#8250c4', '#5ecbc8', '#438fff', '#ff977e', '#eb5757', '#5b2071', '#ec5a96', '#a43876', '#f3c911', '#107c10'],
            data: []
          }
        ]
      },
      noticeList: [],
      noticeDialog: false,
      selectedNotice: {},
      ntisList: []
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      isManager: 'isManager',
      isTeamLeader: 'isTeamLeader'
    })
  },
  mounted() {
    const now = moment()
    this.param.endDate = now.format('YYYY-MM-DD')
    this.param.startDate = now.subtract(1, 'year').startOf('year').format('YYYY-MM-DD')

    this.search()
  },
  methods: {
    search() {
      this.loading = true
      return this.searchState()
        .then(this.searchWorkList)
        .then(this.searchNotDistributeList)
        .then(this.searchNoticeList)
        .then(this.searchNtisList)
        .finally(() => this.loading = false)
    },
    searchState() {
      return this.$http.get('/api/main/state', {params: this.param})
      .then(({ data }) => {
        this.state = data
      })
    },
    searchWorkList() {
      return this.$http.get('/api/main/works', {params: this.param})
      .then(({ data }) => {
        this.workList = data
      })
    },
    searchDepositList() {
      return this.$http.get('/api/main/deposits', {params: this.param})
      .then(({ data }) => {
        this.depositList = data
      })
    },
    searchManagerWorkStateList() {
      return this.$http.get('/api/main/managerworks', {params: this.param})
      .then(({ data }) => {
        this.managerWorkStateList = data
      })
    },
    searchCbnuDepositList() {
      return this.$http.get('/api/main/cbnudeposits', {params: this.param})
      .then(({ data }) => {
        this.cbnuDepositList = data
      })
    },
    searchMainTechTypeStateList() {
      return this.$http.get('/api/main/state/techtype', {params: this.param})
      .then(({ data }) => {
        if (data.length > 0) {
          this.chartData.labels = data.map(d => d.techType)
          this.chartData.datasets[0].data = data.map(d => d.amount)
        }
      })
    },
		searcApprovalWaitList() {
			return this.$http.get('/api/main/approvalWait', {params: this.param})
				.then(({ data }) => {
					this.approvalWaitList = data
				})
		},
		searchNotDistributeList() {
			return this.$http.get('/api/main/notDistribute', {params: this.param})
				.then(({ data }) => {
					this.notDistributeList = data
				})
		},
    searchNoticeList() {
      const params = {
        start: 0,
        end: 5
      }
      return this.$http.get('/api/notice', {params})
        .then(({ data }) => {
          this.noticeList = data
        })
    },
    searchNtisList() {
      return this.$http.get('/api/main/ntis')
        .then(({ data }) => {
          if (data) {
            this.ntisList = data.list
          } else {
            this.ntisList = []
          }
        })
    },
    moveDetail(page, workFlowCode, depositStatus) {
      let path = `/contract/${page}?sw=${workFlowCode}&startDate=${this.param.startDate}&endDate=${this.param.endDate}`
      if (depositStatus) {
        path += `&ds=${depositStatus}`
      }
      this.$router.push(path);
    },
    openApplication(item) {
      window.open(`/application/#/apply/${item.contractTechId}`, item.contractTechId, 'menubar=no,width=1600,height=1000')
    },
    openPaymentView(item) {
      window.open(`/application/#/payment/view/${item.contractTechId}`, item.contractTechId, 'menubar=no,width=1600,height=1000')
    },
    openNotice(item) {
      this.selectedNotice = item
      this.noticeDialog = true
    }
  }
}
</script>
