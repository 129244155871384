<template>
  <v-card>
    <v-card-title class="popup-title">공지사항</v-card-title>
    <v-card-text class="mt-4">
      <div class="a_wrap mt-4">
        <table
          width="100%" cellspacing="0" cellpadding="4px" class="a_box"
        >
          <colgroup>
            <col width="10%">
            <col width="20%">
            <col width="10%">
            <col width="30%">
            <col width="10%">
            <col width="20%">
          </colgroup>
          <tbody v-if="!loading">
          <tr>
            <td class="td_gy">번호</td>
            <td class="td_dark_gray02 text-left">
              {{ editItem.seq }}
            </td>
            <td class="td_gy">등록일</td>
            <td class="td_dark_gray02 text-left">
              {{ editItem.createdDate }}
            </td>
            <td class="td_gy">작성자</td>
            <td class="td_dark_gray02 text-left">
              {{ editItem.writerName }}
            </td>
          </tr>
          <tr>
            <td class="td_gy">제목</td>
            <td class="td_dark_gray02 text-left" colspan="3">
              {{ editItem.subject }}
            </td>
            <td class="td_gy">조회수</td>
            <td class="td_dark_gray02 text-left">
              {{ editItem.views }}
            </td>
          </tr>
          <tr>
            <td class="td_gy">내용</td>
            <td class="td_dark_gray02" style="text-align: left; padding: 6px" colspan="5">
              <div class="ck-editor" v-html="editItem.content"></div>
            </td>
          </tr>
          <tr>
            <td class="td_gy">첨부파일</td>
            <td class="td_dark_gray02 pt-2 text-left" colspan="5">
              <div
                v-for="(file, index) in editItem.files"
                :key="index"
              >
                <v-chip
                  label
                  outlined
                  class="ma-1"
                  @click="downloadFile(file)"
                >
                  {{ file.convFileName }}
                </v-chip>
              </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <th colspan="6" class="td_loading">
              <v-progress-linear
                indeterminate
                color="#0f2585"
              ></v-progress-linear>
            </th>
          </tr>
          </tbody>
        </table>
        <div class="butt">
          <button id="close05" type="button" class="pop-btn" @click="close">닫기</button>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'NoticeViewDialog',
  props: {
    item: Object
  },
  data() {
    return {
      editItem: {},
      loading: false
    }
  },
  mounted() {
    if (this.item.seq) {
      this.loading = true
      this.$http.get(`/api/notice/view/${this.item.seq}`)
        .then(({ data }) => {
          this.editItem = data
          this.loading = false
        })
    }
  },
  methods: {
    downloadFile(fileItem) {
      this.$http.post(`/api/notice/file/download/${fileItem.seq}`, {},{
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileItem.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloadDialog = false
      }).catch(() => {
        alert("파일 다운로드 실패")
        this.downloadDialog = false
      })
    },
    close() {
      this._close()
    },
    _close(result) {
      this.$emit('close', result)
    }
  }
}
</script>

<style>
.ck-editor p {
  text-align: left;
}
.ck-editor img {
  max-width: 100%;
}
</style>
